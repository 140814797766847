<template>
    <div v-show="loading" id="loader">
        <div class="loader-content">
            <div class="loader-content image d-flex align-items-center justify-content-around">
                <div class="spinner-grow text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <!--
            <div class="loader-content message d-flex align-items-center">
                <label class="mx-auto">{{ message }}</label>
            </div>
            -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            message: '',
        }
    },
    methods: {
        showLoading(flg) {
            if (flg) {
                this.message = this.messageInformation('9000');
            }
            this.loading = flg;
        },
        showLoadingWithMessageId(flg, messageId) {
            this.message = this.messageInformation(messageId);
            this.loading = flg;
        }
    },
}
</script>

<style scoped>
#loader {  
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
}
#loader > .loader-content {
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    margin: auto;
    width: 220px;
    height: 220px;
    padding: 1rem;
    text-align: center;
    background-color: #fff;
    opacity: 1.0;
    border-radius: .5rem;
}
#loader > .loader-content > .image {
    height: 100%;
    width: 100%;
}
.spinner-grow{
    width: 1.8rem;
    height: 1.8rem;
}
</style>