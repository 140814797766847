export default {
      setTourAndImages(state, payload){
        state.tourAndImages=payload;
      },
      setTotalPage(state, payload){
        state.totalPage=payload;
      },
      setTourList(state, payload){
        state.tourList=payload;
      },
}