export default {
      setProviderTourList(state, payload){
        state.providerTourList=payload;
      },
      setLatestCreatedTourId(state, payload){
        state.latestCreatedTourId=payload;
      },
      setReservedTourHeading(state, payload){
        state.reservedTourHeading=payload;
      }
}