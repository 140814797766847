export default {
    getProviderTourList(state) {
      return state.providerTourList;
    },
    getLatestCreatedTourId(state) {
      return state.latestCreatedTourId;
    },
    getReservedTourHeading(state) {
      return state.reservedTourHeading;
    }
};