import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store/index.js'

// import LoginProvider from '../view/provider/LoginProvider.vue'
// import LoginUser from '../view/user/LoginUser.vue'
// import ProviderPasswordChange from '../view/provider/PasswordChange.vue'
// import ProviderTourChangeComplete from '../view/provider/TourChangeComplete.vue'
// import ProviderTourDetails from '../view/provider/TourDetails.vue'
// import ProviderTourList from '../view/provider/TourList.vue'
// import ProviderUserInformation from '../view/provider/UserInformation.vue'
// import ProviderReservationList from '../view/provider/ReservationList.vue'
// import ReservationCancel from '../view/user/ReservationCancel.vue'
// import ReservationConfirm from '../view/user/ReservationConfirm.vue'
// import ReservationResult from '../view/user/ReservationResult.vue'
import Top from '../view/user/Top.vue'
// import TourDetails from '../view/user/TourDetails.vue'
// import TourList from '../view/user/TourList.vue'
// import TourReservation from '../view/user/TourReservation.vue'
// import UserInformation from '../view/user/UserInformation.vue'
// import UserMenu from '../view/user/UserMenu.vue'
import HowToUse from '../view/user/HowToUse.vue'
import NotFound from '../view/user/NotFound.vue'

Vue.use(Router)

const ROLE = {
  User: 'User',
  Provider: 'Provider',
};

// should be set the requiresAuth in meta data.
const routes = [
  { path: "/", name: "Top", component: Top, meta: { role:ROLE.User, requiresAuth: false, title: 'オンラインツアーぷらっとワ〜プ - ぷらっとワ〜プでオンラインの旅行に出かけよう！' }},
  { path: "/HowToUse", name: "HowToUse", component: HowToUse, meta: { role:ROLE.User, requiresAuth: false} },
  // { path: "/LoginUser", name: "LoginUser", component: LoginUser, meta: { role:ROLE.User, requiresAuth: false} },
  // { path: "/ReservationConfirm", name: "ReservationConfirm", component: ReservationConfirm, meta: { role: ROLE.User, requiresAuth: true} },
  // { path: "/ReservationCancel", name: "ReservationCancel", component: ReservationCancel,meta: { role: ROLE.User, requiresAuth: true}},
  // { path: "/ReservationResult", name: "ReservationResult", component: ReservationResult, meta: { role: ROLE.User, requiresAuth: true} },
  // { path: "/TourDetails/:tourId", name: "TourDetails", component: TourDetails,meta: { role: ROLE.User, requiresAuth: false, title: 'ツアー内容 − オンラインツアーぷらっとワ〜プ' }},
  // { path: "/TourList", name: "TourList", component: TourList ,meta: { role: ROLE.User, requiresAuth: false, title: 'ツアー一覧 − オンラインツアーぷらっとワ〜プ' }},
  // { path: "/TourReservation", name: "TourReservation", component: TourReservation, meta: { role: ROLE.User, requiresAuth: true} },
  // { path: "/UserMenu", name: "UserMenu", component: UserMenu, meta: { role: ROLE.User, requiresAuth: true} },
  // { path: "/UserInformation", name: "UserInformation", component: UserInformation, meta: { role: ROLE.User, requiresAuth: false} },
  // { path: "/Provider", component: LoginProvider,name:"LoginProvider", meta: { role: ROLE.Provider }},
  // { path: "/Provider/PasswordChange/:providerId", component: ProviderPasswordChange,name:"ProviderPasswordChange", meta: { role: ROLE.Provider, requiresProviderAuth: true} },
  // { path: "/Provider/TourCreate", component: ProviderTourDetails,name:"ProviderTourCreate", meta: { role: ROLE.Provider, requiresProviderAuth: true} },
  // { path: "/Provider/TourDelete/:tourId", component: ProviderTourDetails,name:"ProviderTourDelete", meta: { role: ROLE.Provider, requiresProviderAuth: true} },
  // { path: "/Provider/TourEdit/:tourId", component: ProviderTourDetails,name:"ProviderTourEdit", meta: { role: ROLE.Provider, requiresProviderAuth: true} },
  // { path: "/Provider/TourList", component: ProviderTourList,name:"ProviderTourList", meta: { role: ROLE.Provider, requiresProviderAuth: true} },
  // { path: "/Provider/TourCRUDComplete/:tourId", component: ProviderTourChangeComplete,name:"ProviderTourChangeComplete", meta: { role: ROLE.Provider, requiresProviderAuth: true} },
  // { path: "/Provider/ReservationList/:tourId", name: "ProviderReservationList", component: ProviderReservationList, meta: { role: ROLE.Provider, requiresProviderAuth: true} },
  // { path: "/Provider/UserInformation/:tourId", component: ProviderUserInformation, meta: { role: ROLE.Provider, requiresProviderAuth: true}, children: [
  // { path: "userNo/:userNo", component: ProviderUserInformation, name: 'ProviderUserInformation', meta: { role: ROLE.Provider, requiresProviderAuth: true}},]},
  { path: '*', component: NotFound, name: 'notFound'}
]
const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {  // eslint-disable-line
    return { x:0, y:0 };
  }
});

router.beforeEach((to, from, next) => {
  // redirect to Top page if already log in when accessed the Login page.
  if (to.name == 'LoginUser' && Store.state.authentication.token) {
    next({name: 'Top' });
    return;
  }
  if (to.name == 'LoginProvider' && Store.state.providerAuthentication.providerToken) {
    next({name: 'ProviderTourList'});
    return;
  }
  // if requires requiresAuth but do not have the token in the store, then redirect login view.
  if (to.matched.some(record => record.meta.requiresAuth) && !Store.state.authentication.token&&sessionStorage.getItem('userToken') == null) {
    // set the required parameters at login view ( e.g. callback).
    to.params.callback = to.name;
    next({
      name: 'LoginUser',
      params: to.params
    });
    return;
  }
  // if requires requiresProviderAuth but do not have the token in the provider store, then redirect provider login view.
  if (to.matched.some(record => record.meta.requiresProviderAuth) && !Store.state.providerAuthentication.providerToken&&sessionStorage.getItem('providerToken') == null) {
    // set the required parameters at login view ( e.g. callback).
    to.params.callback = to.name;
    next({
      name: 'LoginProvider',
      params: to.params
    });
    return;
  }

  next();
  return;
});

export default router
