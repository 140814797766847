<template>
<div>
    <link rel="stylesheet" href="https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css" />
    <div class="wrap">
        <app-header v-if="$route.meta.role=='User'" />
        <app-header-provider v-else-if="$route.meta.role=='Provider'" />
        <app-header v-else />
        <router-view />
        <app-footer v-if="$route.meta.role=='User'" />
        <app-footer-provider v-else-if="$route.meta.role=='Provider'" />
        <app-footer v-else />
        <app-loading ref="loading" />
    </div>
</div>
</template>

<script>
import appLoading from "./components/Loading.vue";
import appHeader from "./components/Header.vue";
import appFooter from "./components/Footer.vue";
import appHeaderProvider from "./components/ProviderHeader.vue";
import appFooterProvider from "./components/ProviderFooter.vue";
import store from './store/index'
import router from './router/index'

window.onbeforeunload = function (e) {
    if (store.getters['authentication/getToken'] != null) {
        sessionStorage.setItem("userId", store.getters['authentication/getLoginId']);
        sessionStorage.setItem("userName", store.getters['authentication/getLoginName']);
        sessionStorage.setItem("userToken", store.getters['authentication/getToken']);
    }
    if (store.getters['providerAuthentication/getProviderToken'] != null) {
        sessionStorage.setItem("providerId", store.getters['providerAuthentication/getProviderLoginId']);
        sessionStorage.setItem("providerName", store.getters['providerAuthentication/getProviderLoginName']);
        sessionStorage.setItem("providerToken", store.getters['providerAuthentication/getProviderToken']);
    }
    if (store.getters['reservation/getTourId'] != null) {
        sessionStorage.setItem("tourId", store.getters['reservation/getTourId']);
    }
    if (store.getters['reservation/getNumberOfFellow'] != null) {
        sessionStorage.setItem("numberOfFellow", store.getters['reservation/getNumberOfFellow']);
        sessionStorage.setItem("paymentMethod", store.getters['reservation/getPaymentMethod']);
        sessionStorage.setItem("paymentMethodId", store.getters['reservation/getPaymentMethodId']);
    }
    if (store.getters['reservation/getPreviousPage'] != null) {
        sessionStorage.setItem("reservationResult_PreviousPage", store.getters['reservation/getPreviousPage']);
    }
    if (router.app._route.name == 'ProviderReservationList') {
        if (store.getters['provider/getReservedTourHeading'] != null) {
            sessionStorage.setItem("reservedTourHeading", store.getters['provider/getReservedTourHeading']);
        }
    }
    if (router.app._route.name == 'TourReservation' || router.app._route.name == 'ProviderTourEdit' || router.app._route.name == 'ProviderTourCreate') {
        e.preventDefault();
        e.returnValue = ''
    }
};

//iosデバイスのために(F5押す・新しいページに遷移する時の検出する。)
window.onpagehide  = function (e) {
    if (store.getters['authentication/getToken'] != null) {
        sessionStorage.setItem("userId", store.getters['authentication/getLoginId']);
        sessionStorage.setItem("userName", store.getters['authentication/getLoginName']);
        sessionStorage.setItem("userToken", store.getters['authentication/getToken']);
    }
    if (store.getters['providerAuthentication/getProviderToken'] != null) {
        sessionStorage.setItem("providerId", store.getters['providerAuthentication/getProviderLoginId']);
        sessionStorage.setItem("providerName", store.getters['providerAuthentication/getProviderLoginName']);
        sessionStorage.setItem("providerToken", store.getters['providerAuthentication/getProviderToken']);
    }
    if (store.getters['reservation/getTourId'] != null) {
        sessionStorage.setItem("tourId", store.getters['reservation/getTourId']);
    }
    if (store.getters['reservation/getNumberOfFellow'] != null) {
        sessionStorage.setItem("numberOfFellow", store.getters['reservation/getNumberOfFellow']);
        sessionStorage.setItem("paymentMethod", store.getters['reservation/getPaymentMethod']);
        sessionStorage.setItem("paymentMethodId", store.getters['reservation/getPaymentMethodId']);
    }
    if (store.getters['reservation/getPreviousPage'] != null) {
        sessionStorage.setItem("reservationResult_PreviousPage", store.getters['reservation/getPreviousPage']);
    }
    if (router.app._route.name == 'ProviderReservationList') {
        if (store.getters['provider/getReservedTourHeading'] != null) {
            sessionStorage.setItem("reservedTourHeading", store.getters['provider/getReservedTourHeading']);
        }
    }
    if (router.app._route.name == 'TourReservation' || router.app._route.name == 'ProviderTourEdit' || router.app._route.name == 'ProviderTourCreate') {
        e.preventDefault();
        e.returnValue = ''
    }
};
export default {
    name: 'App',
    components: {
        appLoading,
        appHeader,
        appFooter,
        appHeaderProvider,
        appFooterProvider,
    },
    data() {
        return {
            isProduction: process.env.NODE_ENV === 'production'
        }
    },
    created() {
        if (sessionStorage.getItem('userToken') != null) {
            this.$store.dispatch('authentication/tryLogin');
        }
        if (sessionStorage.getItem("providerToken") != null) {
            this.$store.dispatch('providerAuthentication/tryLoginProvider');
        }
        if (sessionStorage.getItem("tourId") != null) {
            this.$store.dispatch('reservation/SaveTourIdInputs');
        }
        if (sessionStorage.getItem("numberOfFellow") != null) {
            this.$store.dispatch('reservation/SaveReservationInputs');
        }
        if (sessionStorage.getItem("reservationResult_PreviousPage") != null) {
            this.$store.dispatch('reservation/SaveResultPreviousPage');
        }
        if (sessionStorage.getItem("reservedTourHeading") != null) {
            this.$store.dispatch('provider/SaveReservationTourHeading');
        }
    },
    watch: {
        $route: {
            handler: (to) => {
                document.title =
                    to.meta.title ||
                    "オンラインツアーぷらっとワ〜プ - ぷらっとワ〜プでオンラインの旅行に出かけよう！";
            },
            immediate: true,
        }
    },
    methods: {
        showLoading() {
            this.$refs.loading.showLoading(true);
        },
        showLoadingWithMessageId(messageId) {
            this.$refs.loading.showLoadingWithMessageId(true, messageId);
        },
        hiddenLoading() {
            this.$refs.loading.showLoading(false);
        }
    },
};
</script>

<style>
/* ---------- body ---------- */
@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300;400;700&family=Montserrat&display=swap');

body {
    -webkit-text-size-adjust: 100%;
}

p {
    font-size: 0.95rem;
    line-height: 1.8;
    padding: 0 0 20px;
}

a {
    color: #444;
    transition: all 0.2s ease-in-out;
}

dl>dt,
dl>dd {
    font-weight: normal;
    margin: 0;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "M PLUS 1p", "Montserrat";
}

.wr-10 {
    width: 10rem;
}

.table-responsive {
    height: 400px;
}

.content {
    width: 1200px;
    margin: 1rem auto 0 auto;
}

.annotation {
    margin-left: 1rem;
    height: 10px;
}

#footer {
    color: #fff;
    background-color: #0260df;
    padding-top: 1rem;
}

#footer .footer-inner {
    margin: 0 auto;
    max-width: 1200px;
    padding-bottom: 1rem;
    position: relative;
}

#footer p.copy {
    font-size: 0.7rem;
    padding-top: 1rem;
    text-align: center;
}

#footer .footer-logo {
    height: auto;
    width: 5.0rem;
}

/* ---------- tour-list-card ---------- */
.card-title {
    min-height: 3rem;
}

.card-title>a:hover {
    text-decoration: none;
    color: #06c;
}

.capacity {
    padding: 0.25rem 0.5rem;
}

.capacity .present {
    font-size: 1.5rem;
}

.capacity .present i {
    color: #999;
}

.tooltip {
    display: block !important;
    z-index: 10000;
}

.tooltip .tooltip-inner {
    display: flex;
    min-height: 1.8rem;
    align-items: center;
    background-color: #dc3545;
    color: #fff;
    border-radius: 3px;
    padding: 0 .5rem;
}

.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #dc3545;
    z-index: 1;
}

.tooltip[x-placement^="top"] {
    margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
    margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
    margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip[x-placement^="left"] {
    margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
}

.tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
}

/* ---------- detail apply ---------- */
.btn-back {
    margin-left: 1rem;
}

.description {
    color: #808080;
    margin-bottom: 0;
    padding-bottom: 0;
}

@media screen and (min-width: 641px) {

    a[href^="tel:"] {
        color: #444;
        pointer-events: none;
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }
}

@media screen and (max-width: 767px) {

    body {
        min-width: 100%;
    }

    p {
        font-size: 0.9rem;
        line-height: 1.7;
    }

    .content {
        width: 100%;
        margin: 0 auto;
    }

    h2.title{
        margin-left: .5rem;
        font-size: 1.5rem;
    }


    #footer .footer-inner {
        max-width: 100%;
        padding: 0 1rem 1.5rem 1rem;
    }
}
</style>
