import Axios from "axios";
export default {
  async loginProvider(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "Login/Provider";
    await Axios.post(
      apiUrl,
      {
        mail: payload.mail,
        password: payload.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        context.commit("setProviderToken", response.data.accessToken);
        context.commit("setProviderLoginId", response.data.id);
        context.commit("setProviderLoginName", response.data.name);
      })
      .catch((error) => {
        context.commit("setProviderToken", "");
        context.commit("setProviderLoginId", null);
        context.commit("setProviderLoginName", null);
        throw error;
      });
  },

  tryLoginProvider(context) {
    const providerLoginId = sessionStorage.getItem("providerId");
    const providerLoginName = sessionStorage.getItem("providerName");
    const providerToken = sessionStorage.getItem("providerToken");

    if (providerToken && providerLoginId) {
      context.commit("setProviderLoginId", providerLoginId);
      context.commit("setProviderLoginName", providerLoginName);
      context.commit("setProviderToken", providerToken);
    }

    sessionStorage.removeItem("providerToken");
    sessionStorage.removeItem("providerId");
    sessionStorage.removeItem("providerName");
  },

  logoutProvider(context) {
    sessionStorage.removeItem("providerToken");
    sessionStorage.removeItem("providerId");
    sessionStorage.removeItem("providerName");

    context.commit("setProviderToken", null);
    context.commit("setProviderLoginId", null);
    context.commit("setProviderLoginName", null);
  },
};
