import Axios from "axios";
export default {
  async loginUser(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "Login/User";
    await Axios.post(
      apiUrl,
      {
        mail: payload.mail,
        password: payload.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        context.commit("setToken", response.data.accessToken);
        context.commit("setLogin", {
          id: response.data.id,
          name: response.data.name,
        });
      })
      .catch((error) => {
        context.commit("setToken", "");
        context.commit("setLogin", {
          id: null,
          name: null,
        });
        throw error;
      });
  },

  tryLogin(context) {
    const token = sessionStorage.getItem("userToken");
    const loginId = sessionStorage.getItem("userId");
    const loginName = sessionStorage.getItem("userName");

    if (token && loginId) {
      context.commit("setToken", token);
      context.commit("setLogin", {
        id: loginId,
        name: loginName,
      });
    }
    //ユーザ情報を変更時新しいユーザ名をvuexに設定。
    let newLoginName;
    if (sessionStorage.getItem("newUserName") != undefined) {
      newLoginName = sessionStorage.getItem("newUserName");
    }
    if (newLoginName != undefined) {
      context.commit("setLogin", {
        name: newLoginName,
      });
    }
    sessionStorage.removeItem("userToken");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("newUserName");
  },

  logout(context) {
    sessionStorage.removeItem("userToken");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("userName");

    context.commit("setToken", null);
    context.commit("setLogin", {
      id: null,
      name: null,
    });
  },
};
