<template>
<div class="d-flex flex-wrap">
    <div class="card" v-for="(tourInfo,index) in tourListInfo" :key="index">
        <div class="card-top">
            <img v-if="tourInfo.imageCount > 0" border="0" :src='apiUrl + "user/tourImage/" + tourInfo.tourId + "/1"' >
            <span class="badge place badge-success">{{tourInfo.place}}</span>
            <!-- <span class="badge date badge-dark">2021年<span>2</span>月<span>24</span>日 14:00-18:00</span> -->
            <span class="badge date badge-dark">
                {{ tourInfo.tourDate | tour_date_format('y') }}年                
                <span>{{ tourInfo.tourDate | tour_date_format('m') }}</span>月
                <span>{{ tourInfo.tourDate | tour_date_format('d') }}</span>日 
                {{tourInfo.tourTimeFrom | tour_time_format }}-{{tourInfo.tourTimeTo | tour_time_format }}
            </span>
        </div>
        <div class="card-body">
            <h5 class="card-title">
                <a :href="''" @click="goToTourDetailsPage(tourInfo)" class="link">{{tourInfo.heading}}</a>
            </h5>
            <div class="card-progress">
                <div class="progress">
                    <div v-if="tourInfo.reserveCount/tourInfo.capacity<0.8" class="progress-bar" role="progressbar" :style="{width:(tourInfo.reserveCount/tourInfo.capacity)*100 + '%'}" :aria-valuenow="tourInfo.reserveCount" :aria-valuemin="0" :aria-valuemax="tourInfo.capacity"></div>
                    <div v-else-if="tourInfo.reserveCount/tourInfo.capacity>=1.0" class="progress-bar" role="progressbar" :style="{width:(tourInfo.reserveCount/tourInfo.capacity)*100 + '%', backgroundColor:'gray'}" :aria-valuenow="tourInfo.reserveCount" :aria-valuemin="0" :aria-valuemax="tourInfo.capacity"></div>
                    <div v-else class="progress-bar" role="progressbar" :style="{width:(tourInfo.reserveCount/tourInfo.capacity)*100 + '%', backgroundColor:'#dc3545'}" :aria-valuenow="tourInfo.reserveCount" :aria-valuemin="0" :aria-valuemax="tourInfo.capacity"></div>
                </div>
            </div>
            <div class="card-detail">
                <div class="capacity mr-auto my-auto" v-if="(tourInfo.reserveCount/tourInfo.capacity)>0.8&&(tourInfo.reserveCount/tourInfo.capacity)<1">
                    <span class="present" v-tooltip="{content:'残りわずか！', trigger:'manual', show:true}"><i class="las la-user-friends"></i> {{tourInfo.reserveCount}}</span>
                    <span class="limit"> /{{tourInfo.capacity}}人</span>
                </div>
                <div class="capacity mr-auto" v-else>
                    <span class="present"><i class="las la-user-friends"></i> {{tourInfo.reserveCount}}</span>
                    <span class="limit"> /{{tourInfo.capacity}}人</span>
                </div>
                <button class="btn btn-outline-primary btn-detail" @click="goToTourDetailsPage(tourInfo)">詳細</button>
                <button class="btn btn-light btn-entry" v-if="isReserved(tourInfo.tourId)" disabled>予約済</button>
                <button class="btn btn-light btn-entry" v-else-if="tourInfo.releaseStatus == '2'" disabled>募集前</button>
                <button class="btn btn-outline-danger btn-entry" v-else-if="(tourInfo.reserveCount>=tourInfo.capacity)" disabled>満員御礼</button>
                <button class="btn btn-light btn-entry" v-else-if="tourInfo.releaseStatus == '3'" disabled>募集終了</button>
                <button class="btn btn-warning btn-entry" @click="reserveToDetailsPage(tourInfo)" v-else><i class="las la-ticket-alt"></i> 申込</button>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
/* ---------- card ---------- */
.card-top {
    position: relative;
    height: 200px;
    overflow-y: hidden;
}

.card-top img {
    width: 100%;
}

.card-top .badge.place {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
}

.card-top .badge.date {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 0;
    font-family: "M PLUS 1p", "Montserrat";
    font-weight: normal;
    padding: .25rem .4rem .3rem .4rem;
}

.card-top .badge.date span{
    font-size: .95rem;
    padding: 0 .03rem;
}

.annotation {
    margin-left: 1rem;
    height: 10px;
}

.card {
    width: 368px;
    margin: 1rem;
    border: 0 none;
    border-radius: 5px;
}

.card-top img{
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.card-body {
    border-right: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    border-left: 1px solid #d0d0d0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.card-detail {
    display: flex;
    justify-content: flex-end;
}

.card-progress .progress {
    height: 5px;
    border-radius: 0;
    margin-bottom: 0.75rem;
}

.btn-detail {
    width: 4.5rem;
    margin-right: 0.5rem;
}
.btn-entry {
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .card {
        width: 100%;
        margin: 0 0 1.5rem 0;
        border: 0 none;
        border-radius: 0;
    }

    .card .card-body {
        padding: 1rem;
        border: 0 none;
        border-radius: 0;
    }

    .card .card-title {
        min-height: auto;
    }

    .card-img,
    .card-img-top {
        border-radius: 0;
    }
}
</style>

<script>
import {} from 'vuex'

export default {
    name: "tourListCard",
    props: {
        tourListInfo: {
            type: Array,
        },
        reservedList: {
            type: Array
        },
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
        }
    },
    computed: {
        reservedTourList() {
            return this.reservedList.map(item => item.tourId)
        }
    },
    methods: {
        isReserved(currentTourId) {
            if (this.reservedTourList.includes(currentTourId)) {
                return this.reservedTourList.includes(currentTourId)
            }
        },
        goToTourDetailsPage(tour) {
            this.$router.push({
                name: "TourDetails",
                params: {
                    tourId: tour.tourId,
                },
            });
        },
        reserveToDetailsPage(tour) {
            this.$router.push({
                name: "TourReservation",
                params: {
                    tourId: tour.tourId,
                },
            })
        },
    }
}
</script>
