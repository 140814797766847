import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Vuex from 'vuex'
import store from './store/index.js'
import BootstrapVue from 'bootstrap-vue'
import 'popper.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import tourListCard from './components/tourListCard.vue'
import tourDetailsCard from './components/tourDetailsCard.vue'
import imageInputCard from './components/ImageInputCard.vue'
import emptyTourList from "./components/EmptyTourList.vue";
import VTooltip from 'v-tooltip'
import MessageUtility from './mixins/MessageUtility.js'
// import { BFormSelect } from 'bootstrap-vue'
// Vue.component('b-form-select', BFormSelect)

Vue.mixin(MessageUtility)

Vue.component('tour-list-card',tourListCard)
Vue.component('tour-details-card',tourDetailsCard)
Vue.component('image-input-card', imageInputCard)
Vue.component('empty-tour-list', emptyTourList)
Vue.component('font-awesome-icon')

Vue.use(Vuex)
Vue.use(VTooltip)
Vue.use(BootstrapVue)

Vue.prototype.$axios = axios //追加

require('./assets/js/filter');

new Vue({
  render: h => h(App),
  router,
  store,
  axios
}).$mount('#app')
