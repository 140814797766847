import Axios from "axios";
const parameters = {
  sortOrder: 'sortOrder',
  numInPage: 'numInPage',
  tourIds: 'tourIds',
  providerIds: 'providerIds',
  userNos: 'userNos',
  pageNo: 'pageNo',
  imageNum: 'imageNum',
  place: 'place',
  freewords: 'freewords',
  wanted: 'wanted'
};

export default {
  async searchTour(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "user/search/tours";

    var params = '';
    Object.keys(parameters).forEach(key => {
      if (payload[key]) {
        if (params.length > 0) {
          params += "&";
        } else {
          params += "?";
        }
        params += key + "=" + payload[key];
      }
    });
    apiUrl += params;        
    var getHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setTourAndImages", response.data.searchTourAndImages);
        context.commit("setTotalPage", response.data.totalPage);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  //Problem with the data received from the API, returning all the tours but only reserved tour is needed.
  async userTours(context) {
    var apiUrl = process.env.VUE_APP_API_URL;
    var getAccessToken =
      "Bearer " + context.rootGetters["authentication/getToken"];
    apiUrl += "user/tours/";
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setTourList", response.data.tour);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
