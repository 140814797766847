import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from 'vuex-persistedstate'

import authenticationModule from './modules/authentication/index';
import generalModule from './modules/general/index';
import providerAuthenticationModule from './modules/providerAuthentication/index';
import providerModule from './modules/provider/index';
import reservationModule from './modules/reservation/index';
import tourModule from './modules/tour/index';
import tourDetailsModule from './modules/tourDetails/index';
import tourImagesModule from './modules/tourImages/index';
import userModule from './modules/user/index';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        authentication:authenticationModule,
        providerAuthentication:providerAuthenticationModule,
        general:generalModule,
        provider:providerModule,
        reservation:reservationModule,
        tour:tourModule,
        tourDetails:tourDetailsModule,
        tourImages:tourImagesModule,
        user:userModule,
    },
    // create instance using `createPersistedState()`
    // set the settings to arguments.
    // plugins: [
    //     createPersistedState({
    //         // specifying storage key. (default: vuex)
    //         key: "platwarp-vuex-project",

    //         // specifying states managed. if not specify the paths then target all states in `modules`.
    //         // when set `[]` then all states don't save.
    //         paths: ["authentication.token", "providerAuthentication.providerToken"],

    //         // specifying kind of storage. (default: localStorage)
    //         storage: window.sessionStorage,
    //     })
    // ],
});

export default store;