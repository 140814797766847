import Axios from "axios";
export default {
  //Problem with the data received from the API, returning all the tours but only reserved tour is needed.
  async providerTourList(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    var getAccessToken =
      "Bearer " +
      context.rootGetters["providerAuthentication/getProviderToken"];
    apiUrl += "provider/tours";
    var params = {
      providerId: payload,
      sortOrder: "coming",
    };
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };
    Axios.get(apiUrl, getHeader, params)
      .then((response) => {
        context.commit("setProviderTourList", response.data.tour);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  async createTour(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    var getAccessToken =
      "Bearer " +
      context.rootGetters["providerAuthentication/getProviderToken"];
    apiUrl += "provider/tours/";
    var params = {
      providerId: payload.providerId,
      tourDate: payload.tourDate,
      tourTimeFrom: payload.tourTimeFrom,
      tourTimeTo: payload.tourTimeTo,
      heading: payload.heading,
      plannerName: payload.plannerName,
      plannerInfo: payload.plannerInfo,
      content: payload.content,
      place: payload.place,
      capacity: payload.capacity,
      flow: payload.flow,
      souvenir: payload.souvenir,
      path: payload.path,
      price: payload.price,
      payee: payload.payee,
      cancelInfo: payload.cancelInfo,
      remarks: payload.remarks,
      notes: payload.notes,
      statusNo: payload.statusNo,
      releaseStartDate: payload.releaseStartDate,
      releaseEndDate: payload.releaseEndDate,
      TourImages: payload.tourImages,
    };
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };
    await new Promise((resolve, reject) => {
      Axios.post(apiUrl, params, getHeader)
      .then((response) => {
        if (response.data.processCheck == true) {
        context.commit("setLatestCreatedTourId", response.data.tourId);
          resolve(response)
        } else
        {
          reject(response)
        }
      })
      .catch((error) => {
          reject(error.response)
      })
    })
  },
  async editTour(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    var getAccessToken =
      "Bearer " +
      context.rootGetters["providerAuthentication/getProviderToken"];
    apiUrl += "provider/tours/" + payload.tourId;
    var params = {
      providerId: payload.providerId,
      tourDate: payload.tourDate,
      tourTimeFrom: payload.tourTimeFrom,
      tourTimeTo: payload.tourTimeTo,
      heading: payload.heading,
      plannerName: payload.plannerName,
      plannerInfo: payload.plannerInfo,
      content: payload.content,
      place: payload.place,
      capacity: payload.capacity,
      flow: payload.flow,
      souvenir: payload.souvenir,
      path: payload.path,
      price: payload.price,
      payee: payload.payee,
      cancelInfo: payload.cancelInfo,
      remarks: payload.remarks,
      notes: payload.notes,
      statusNo: payload.statusNo,
      releaseStartDate: payload.releaseStartDate,
      releaseEndDate: payload.releaseEndDate,
      TourImages: payload.tourImages,
    };
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };
    await new Promise((resolve, reject) => {
      Axios.put(apiUrl, params, getHeader)
      .then((response) => {
        if (response.data.processCheck == true) {
          resolve(response)
        } else
        {
          reject(response)
        }
      })
      .catch((error) => {
          reject(error.response)
      })
    })
  },
  async deleteTour(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    var getAccessToken =
      "Bearer " +
      context.rootGetters["providerAuthentication/getProviderToken"];
    apiUrl += "provider/tours/" + payload.tourId;
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };
    await new Promise((resolve, reject) => {
      Axios.delete(apiUrl, getHeader)
      .then((response) => {
        if (response.data.processCheck == true) {
          resolve(response)
        } else
        {
          reject(response)
        }
      })
      .catch((error) => {
          reject(error.response)
      })
    })
  },
  async changeProviderPassword(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "provider/Providers";
    var token =
      "Bearer " +
      context.rootGetters["providerAuthentication/getProviderToken"];
    var getHeader = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    var params = {
      new_password: payload.newPassword,
      old_password: payload.oldPassword,
    };
    await new Promise((resolve, reject) => {
      Axios.put(apiUrl, params, getHeader)
      .then((response) => {
        if (response.data.processCheck == true) {
          resolve(response)
        } else
        {
          reject(response)
        }
      })
      .catch((error) => {
          reject(error.response)
      })
    })
  },
  SaveReservationTourHeading(context) {
    const reservedTourHeading = sessionStorage.getItem("reservedTourHeading");
    if(reservedTourHeading){
    context.commit('setReservedTourHeading', reservedTourHeading)}
    sessionStorage.removeItem("reservedTourHeading");
  }
};
