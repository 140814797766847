import Axios from "axios";
export default {
    async user(context, payload){
        var apiUrl=process.env.VUE_APP_API_URL
        var token;
        //If Provider Login then use Provider's token, If UserLogin then use User's token
        if (context.rootGetters["providerAuthentication/isAuthenticatedProvider"]) {
            apiUrl+="provider/users/" + payload;
            token =
            "Bearer " +
            context.rootGetters["providerAuthentication/getProviderToken"];
        } else if (context.rootGetters["authentication/isAuthenticated"]) {
            apiUrl+="user/users";
            token = "Bearer " + context.rootGetters["authentication/getToken"];
        }
        var getHeader = {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        };
        await Axios.get(apiUrl,getHeader)
        .then((response) => {
            context.commit("setUser",response.data)
        })
        .catch((error) => {
            console.log(error);
            throw error; 
        })
    },
    async searchUserInfo(context){
        var apiUrl=process.env.VUE_APP_API_URL
        apiUrl+="user/users";
        var token = "Bearer " + context.rootGetters["authentication/getToken"];
        var getHeader = {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        };
        await Axios.get(apiUrl,getHeader)
        .then((response) => {
            context.commit("setUserInfo",response.data)})
    },
    async insert(context, payload){
        var apiUrl=process.env.VUE_APP_API_URL
        apiUrl+="user/users";
        var getHeader = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await new Promise((resolve, reject) => {
            Axios.post(apiUrl,payload,getHeader)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error.response)
            })
        })
    },
    async update(context, payload){
        var apiUrl=process.env.VUE_APP_API_URL
        apiUrl+="user/users";
        var token = "Bearer " + context.rootGetters["authentication/getToken"];
        var getHeader = {
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        };
        await new Promise((resolve, reject) => {
            Axios.put(apiUrl,payload,getHeader)
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error.response)
            })
        })
    },
};
