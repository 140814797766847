import Axios from "axios";
export default {
  async tourDetail(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "provider/tours" + "/" + payload.tourId;
    var token = "Bearer " + context.rootGetters["providerAuthentication/getProviderToken"];
    var getHeader = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setTourDetails", response.data);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },

  async tourDetailByAllowAnonymous(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "user/tours" + "/" + payload.tourId;
    var getHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setTourDetails", response.data);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
