export default {
    data() {
        return {
            source: {},
            messageType: {
                'I': 'Information',
                'Q': 'Question',
                'W': 'Warning',
                'E': 'Error',
            },
        };
    },
    mounted() {
        (function(){
            if (!String.prototype.format) {
                String.prototype.format = function () {
                    var args = arguments;
                    return this.replace(/{(\d+)}/g, function (match, number) {
                        return typeof args[number] != 'undefined'? args[number]: match;
                    });
                };
            }
        })();

        this.$axios
        .get("/messages.json")
        .then(response => {
            this.source = response.data;
        }).catch((error) => {
            console.log('MessageUtility: axios error.');
            console.log(error);
        });
    },
    methods: {
        messageInformation(code, ...args) {
            var message = this.getMessage(this.messageType.I, code);
            return message.format(...args);
        },
        messageQuestion(code, ...args) {
            var message = this.getMessage(this.messageType.Q, code);
            return message.format(...args);
        },
        messageWarning(code, ...args) {
            var message = this.getMessage(this.messageType.W, code);
            return message.format(...args);
        },
        messageError(code, ...args) {
            var message = this.getMessage(this.messageType.E, code);
            return message.format(...args);

        },
        getMessage(type, code) {
            try {
                if (!this.source.message) {
                    // sometimes messages.js hasn't loaded (e.g. running mounted ).
                    // for the time being to set initial message to 'proccessing'.
                    // return 'メッセージファイルを読み込めませんでした。';
                    return '処理中...';
                }
                return this.source.message[type][code];
            } catch(error) {
                console.error('id:' + type + ', code:' + code);
                return this.source.message.Error['9001'];
            }
        },
        template(strings, ...keys) {
            return (function(...values) {
                var result = [strings[0]];
                keys.forEach(function(key, i) {
                    var value = values[key];
                    result.push(value, strings[i + 1]);
                });
                return result.join('');
            });
        }
    }
}