export default {
    setProviderToken(state, payload){
        state.providerToken=payload;
    },
    setProviderLoginId(state, payload){
        state.providerLoginId=payload;
    },
    setProviderLoginName(state, payload){
        state.providerLoginName=payload;
    },
  }