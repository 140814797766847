<template>
<div id="header">
    <div class="inner">
        <div class="logo">
            <a href="/" rel="home"><h1><img src="../static/images/logo_header.svg" alt="ぷらっとワ〜プ"></h1></a>
        </div>

        <!--
        <div v-if="$route.name!='LoginUser'" class="user-info">
            <div v-if="isLoggedIn" class="pre">
                <label>ようこそ</label>
                <label class="user-name">{{loginName}}</label>
                <label>さん</label>
            </div>
            <div v-else class="pre">
                <label>ログインIDをお持ちの方</label>
            </div>
            <div id="header-menu" v-if="!isLoggedIn">
                <button type="button" class="btn btn-info" @click="login">
                    <i class="las la-user-lock"></i><span>ログイン</span>
                </button>
            </div>
            <div id="header-menu" v-else>
                <button type="button" class="btn btn-info" @click="userMenu">
                    <i class="las la-user-circle"></i><span>ユーザメニュー</span>
                </button>
                <button type="button" class="btn btn-light" @click="logout">
                    <i class="las la-door-open"></i><span>ログアウト</span>
                </button>
            </div>
        </div>
        -->
    </div>
</div>
</template>

<style scoped>
#header{
    background-color: #fff;
    width: 100%;
    min-height: 80px;
}
#header .inner{
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
}
#header h1 {
    width: 283px;
    margin: 6px 0;
}
#header .user-info{
    display: flex;
    margin-left: auto;
    align-items: center;
}
.user-name{
    margin: 0 .5rem;
    font-size: 1rem;
    color: #222;
}
.pre{
    font-size: .9rem;
    margin-right: .7rem;
    margin-top: .5rem;
    color: #444;
}
#header-menu{
    display: flex;
}
#header-menu > .btn{
    display: flex;
    margin-right: .7rem;
}
#header-menu > .btn > i {
    font-size: 24px;
    margin-right: 5px;
}
#header-menu > .btn:last-child {
    margin-right: 0;
}

@media screen and (max-width: 767px) {
    #header .inner{
        flex-direction: column;
    }
    #header h1 {
        min-width: 100%;
        margin: 6px 0;
    }
    #header .user-info{
        flex-direction: column;
        margin: 0 0 .7rem 0;
    }
}
</style>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    computed: {
        ...mapGetters({
            loginId: 'authentication/getLoginId',
            loginName: 'authentication/getLoginName',
            isLoggedIn: 'authentication/isAuthenticated',
        }),
    },
    methods: {
        ...mapActions(['authentication/logout']),
        login() {
            this.$router.push({
                name: "LoginUser",
                params: {
                    tourId: null,
                    callback: "Top"
                },
            });
        },
        userMenu() {
            this.$router.push({
                name: "UserMenu",
                params: {
                    userId: this.loginId,
                },
            }).catch(()=>{
            });
        },
        logout() {
            var result = window.confirm('ログアウトします。\r\nよろしいですか？');
            if (result) {
                this.$store.dispatch('authentication/logout');
                window.location.href = '/';
            }
        },
    }
}
</script>
