import Axios from "axios";
export default {
  async tourImages(context, payload) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "provider/tourImages" + "/" + payload.tourId;
    var token = "Bearer " + context.rootGetters["providerAuthentication/getProviderToken"];
    var getHeader = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setTourImage", response.data.tourImage);
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },

  async clearImageGetter(context){
    context.commit("setTourImage", null);
  }
};
