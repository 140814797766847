export default {
  setSexs(state, payload){
    state.sexs=payload;
  },
  setPaymentOptions(state, payload){
    state.paymentOptions=payload;
  },
  setAreas(state, payload){
    state.areas=payload;
  },
  setStatus(state, payload){
    state.status=payload;
  },
}