import Axios from "axios";
export default {
  async getSexLists(context) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "general/" + "0100";
    var getHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setSexs", response.data.general);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async getPaymentOptionLists(context) {
    var apiUrl=process.env.VUE_APP_API_URL
    apiUrl+="general/"+"0200"
    var getHeader = {
      headers: {
          "Content-Type": "application/json",
      },
    };
    Axios.get(apiUrl,getHeader)
    .then((response) => {
      context.commit("setPaymentOptions",response.data.general)
    })
    .catch((error) => {
      console.log(error);
    });
  },

  async getAreaLists(context) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "general/" + "0400";
    var getHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setAreas", response.data.general);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async getStatusLists(context) {
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "general/" + "0300";
    var getHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setStatus", response.data.general);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
