<template>
<div>
    <div class="slider-wrap">
        <VueSlickCarousel id="carousel" v-bind="settings">
            <img src="../../static/images/0066.jpg">
            <img src="../../static/images/slider-1.jpg">
            <img src="../../static/images/slider-2.jpg">
            <img src="../../static/images/slider-3.jpg">
        </VueSlickCarousel>
        <div class="slider-text-wrap">
            <div class="slider-text">
                <h2>ステイホーム・ゴートラベル。</h2>
                <div>
                    <p>手続きはたったの3ステップ。たったこれだけで、きっと驚くほどの体験ができます。</p>
                    <!--
                    <button href="#tour-list" class="btn btn-primary" @click="topPageToTourListPage">今すぐ体験してみる</button>
                    -->
                </div>
            </div>
        </div>
    </div>
    <div class="flip-boxes">
        <h2>ぷらっとワ〜プとは？</h2>
        <div class="flip-boxes-inner">
            <div class="flip-box">
                <div class="front front01">
                    <div class="content">
                        <h4>現地から生中継。</h4>
                        <p>地域を知るガイドさんと楽しく話しながら、一緒に旅を楽しむことができます。</p>
                    </div>
                </div>
                <div class="back back01">
                    <div class="content">
                        <h5>旅行先はどんどん増えています。</h5>
                        <p>行ったことのない素敵な場所を見ることができ、実際に足を運んでみたくなるでしょう。</p>
                    </div>
                </div>
            </div>
            <div class="flip-box">
                <div class="front front02">
                    <div class="content">
                        <h4>必要なのはスマホだけ。</h4>
                        <p>Zoomを利用したオンライン体験なので、旅行の準備も不要。お家から気楽に参加できます。</p>
                    </div>
                </div>
                <div class="back back02">
                    <div class="content">
                        <h4>Zoomの使い方はシンプル。</h4>
                        <p>登録してもらうメールアドレスにも詳しい操作方法をお送りします。</p>
                    </div>
                </div>
            </div>
            <div class="flip-box">
                <div class="front front03">
                    <div class="content">
                        <h4>みんなで楽しめる。</h4>
                        <p>一人旅も良いですが、友人や家族と一緒に歓談しながら旅を楽しむこともできます。</p>
                    </div>
                </div>
                <div class="back back03">
                    <div class="content">
                        <h4>みんなで楽しめる。</h4>
                        <p>一人旅も良いですが、友人や家族と一緒に歓談しながら旅を楽しむこともできます。</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="promotion">
            <div class="body">
                <h4>サービス利用イメージ</h4>
                <p>ぷらっとワ〜プの体験イメージを動画でご確認ください。</p>
            </div>
            <div class="video">
                <video src="../../static/movies/service-concept.mp4" controls></video>
            </div>
        </div>
    </div>
    <div class="content">
        <!--
        <div class="tour" id="tour-list">
            <section>
                <h2 class="title">オンラインツアー</h2>
                <div class="d-flex flex-wrap">
                    <tour-list-card :tourListInfo="getTourInfos" :reservedList="reservedTourList"></tour-list-card>
                </div>
                <div class="text-right mr-3 mt-3"><button class="btn btn-primary" @click="topPageToTourListPage">すべてのツアーを見る</button></div>
            </section>
        </div>
        -->
        <div class="step-wrap">
            <section>
                <h2 class="title">ご利用のステップ</h2>
                <div class="step">
                    <div class="step-item step-1">
                        <h5>本サイトからお申し込み</h5>
                        <p>ツアー一覧から参加したい旅行プランを選んで、お申し込み。定員が決まっているプランもありますので、お早めに申し込みをお願いします。</p>
                    </div>
                    <div class="step-item step-2">
                        <h5>旅行代金のお支払い</h5>
                        <p>予約完了メールが届いたら、案内に従って代金のお支払いをお願いします。現在、銀行振込のみ受け付けております。</p>
                    </div>
                    <div class="step-item step-3">
                        <h5>当日、PCやスマホからツアー参加</h5>
                        <p>入金確認後、オンラインツアーの案内が届きます。案内に従ってPCやスマホ上のZOOMアプリからオンラインツアーに参加してください。</p>
                    </div>
                </div>
                <div class="step-detail text-right mr-3">
                    <button class="btn btn-primary" @click="clickHowToUse">くわしい利用の流れについて</button>
                </div>
            </section>
        </div>
        <div class="faq-wrap">
            <section>
                <h2 class="title">よくある質問</h2>
                <div class="faq">
                    <div class="faq-item">
                        <h5>参加するために何が必要ですか？</h5>
                        <p>インターネットにつながっているPCまたはスマートフォンさえあれば問題ありません。詳しくは利用案内をご確認ください。</p>
                    </div>
                    <div class="faq-item">
                        <h5>ツアーの時間はどれくらいですか？</h5>
                        <p>ツアーのプランによりますが、1時間から3時間程度のツアーが多いです。</p>
                    </div>
                    <div class="faq-item">
                        <h5>1人でも楽しめますか？</h5>
                        <p>お一人様での参加を基本としています。経験豊富なガイドさんが案内をしながら、細かな要望に答えてくれます。<br>きっと楽しい体験になるはずです！</p>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
</template>

<style scoped>
/* ---------- slider ---------- */
.slider-wrap {
    position: relative;
    width: 100%;
    max-height: 550px;
    overflow: hidden;
}

.slider-text-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 100px 0 0 2rem;
    background-image: linear-gradient(90deg, rgba(5, 5, 5, .5) 31%, rgba(0, 0, 0, 0) 100%);
    transition: background .3s, border-radius .3s, opacity .3s;
}

.slider-text {
    font-size: .8rem;
    color: #fff;
    font-family: Montserrat;
}

.slider-text h2 {
    font-family: Montserrat;
    font-weight: 700;
}

/* ---------- flip boxes ---------- */
.flip-boxes {
    width: 1200px;
    margin: 3rem auto;
}

.flip-boxes h2 {
    margin-bottom: 3rem;
}

.flip-boxes-inner {
    display: flex;
    flex-wrap: wrap;
    background: url("../../static/images/logo_gray.svg") no-repeat 50% 50%;
    background-size: 95%;
}

.flip-box {
    width: 368px;
    display: flex;
    align-content: stretch;
    min-height: 200px;
    flex-wrap: wrap;
    position: relative;
    margin: 0 17px;
    padding: 0;
    border-top: 0;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

.flip-box:first-child {
    margin-left: 12px;
}

.flip-box:last-child {
    margin-right: 0;
}

.flip-box:hover .back {
    transform: rotateY(0deg);
    z-index: 10;
}

.flip-box:hover .front {
    transform: rotateY(180deg);
    z-index: -1;
}

.flip-box .back,
.flip-box .front {
    width: 368px;
    height: 450px;
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    -webkit-transition: all 1s cubic-bezier(0.5, 1, 0.5, 1);
    transition: all 1s cubic-bezier(0.5, 1, 0.5, 1);
    transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    background-position: center;
}

.flip-box .front {
    background-repeat: no-repeat;
    align-items: flex-start;
    padding-top: 2.5rem;
}

.flip-box .front01 {
    background-color: #e0ecff;
    background-image: url(../../static/images/broadcast.svg);
    background-size: 90%;
    background-position: 50% 100%;
}

.flip-box .front02 {
    background-color: #ffeaec;
    background-image: url(../../static/images/smartphone.svg);
    background-size: 70%;
    background-position: 70% 100%;
}

.flip-box .front03 {
    background-color: #f2edbc;
    background-image: url(../../static/images/vchat.svg);
    background-size: 80%;
    background-position: 50% 100%;
}

.flip-box .back {
    background-image: url(../../static/images/flip01.jpg);
    background-position: 30% 50%;
    background-size: 200%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    transform: rotateY(-180deg);
}

.flip-box .back01 {
    background-image: linear-gradient(red, yellow, green);
    background-image: url(../../static/images/flip01.jpg);
}

.flip-box .back02 {
    background-image: linear-gradient(red, yellow, green);
    background-image: url(../../static/images/flip02.jpg);
}

.flip-box .back03 {
    background-image: linear-gradient(red, yellow, green);
    background-image: url(../../static/images/flip03.jpg);
}

.flip-box .back::before {
    content: " ";
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.flip-box .front {
    z-index: 10;
}

.flip-box .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    -webkit-transform: translateZ(50px);
    transform: translateZ(50px);
}

.flip-box .front01 .content h4,
.flip-box .front01 .content p {
    color: #06c;
}

.flip-box .front02 .content h4,
.flip-box .front02 .content p {
    color: #d64040;
}

.flip-box .front03 .content h4,
.flip-box .front03 .content p {
    color: #a57100;
}

.flip-box .content p {
    padding: 0.5rem 2.1rem;
}

.promotion {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 30px);
    margin: 30px 15px 30px 12px;
}
.promotion div.body{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    align-self: stretch;
    justify-self: flex-start;
    background-color: #1d262d;
    background: #1d262d url("../../static/images/bg-promotion.svg") no-repeat 90% 100%;
    background-size: 40%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 3.4rem 2rem 0 2rem;
}
.promotion div.body h4{
    margin-bottom: 1rem;
}
.promotion div.body h4,
.promotion div.body p{
    color: #fff;
}
.promotion div.video{
    line-height: 0;
}
.promotion div.video video{
    width: 768px;
    margin: 0 0 0 auto;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    outline: none;
}

.tour {
    margin-bottom: 2rem;
}

.faq-wrap,
.step-wrap {
    margin-bottom: 2rem;
}
.step-wrap h2.title,
.faq-wrap h2.title{
    margin-bottom: 3rem;
}

.faq,
.step {
    display: flex;
    margin-top: 2rem;
}

.faq-item,
.step-item {
    width: 368px;
    display: flex;
    align-content: stretch;
    min-height: 200px;
    flex-wrap: wrap;
    margin: 0 17px;
    padding: 0;
}

.faq-item:first-child,
.step-item:first-child {
    margin-left: 12px;
}

.faq-item:last-child,
.step-item:last-child {
    margin-right: 0;
}

.faq-item > h5{
    position: relative;
    padding-left: 100px;
    min-height: 4.8rem;
}
.faq-item > h5:before{
    position: absolute;
    content: "";
    top: -1.5rem;
    left: -.5rem;
    display: block;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat; 
    background-image: url("../../static/images/faq-question.svg");
    background-size: 100px;
}
.faq-item > p{
    font-size: .9rem;
    min-height: 12rem;
    padding: .75rem;
    color: #444;
    background-repeat: no-repeat; 
    background-image: url("../../static/images/faq-answer.svg");
    background-position: 97% 92%;
    background-size: 100px;
    border: 2px solid #e0e0e0;
    border-radius: 5px;
}

.step-item{
    min-height: 480px;
    align-content: stretch;
    background-repeat: no-repeat;
    background-position: 50% 3rem;
    background-size: 100%;
}
.step-item > p{
    font-size: .9rem;
    margin-top: auto;
    min-height: 7rem;
    padding: 1rem;
    color: #444;
    background-color: #f5f5f5;
    border-radius: 5px;
}
.step-item > h5{
    position: relative;
    padding-left: 100px;
}
.step-item > h5:before{
    position: absolute;
    content: "";
    top: -1.5rem;
    left: -.5rem;
    display: block;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat; 
}
.step-1 > h5:before{
    background-image: url("../../static/images/step-title-01.svg");
}
.step-2 > h5:before{
    background-image: url("../../static/images/step-title-02.svg");
}
.step-3 > h5:before{
    background-image: url("../../static/images/step-title-03.svg");
}
.step-1{
    background-image: url("../../static/images/step-01.svg");
    background-size: 70%;
}
.step-2{
    background-image: url("../../static/images/step-02.svg");
}
.step-3{
    background-image: url("../../static/images/step-03.svg");
}
.content{
    margin-bottom: 0;
}
.corporate{
    color: #fff;
    background-color: #0260df;
    padding-top: 1rem;
}


@media screen and (max-width: 767px) {

    /* FLIP-BOX */
    .flip-boxes {
        width: 100%;
        margin: 3rem auto;
    }

    .flip-boxes h2 {
        font-size: 1.75rem;
        text-align: center;
        margin-bottom: 1.8rem;
    }

    .flip-boxes-inner {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .flip-box {
        width: 100%;
        margin: 0;
    }

    .flip-box:first-child {
        margin-left: 0;
    }

.promotion {
    flex-direction: column;
    width: 100%;
    margin: 0;
}
.promotion div.body{
    width: 100%;
    justify-self: center;
    text-align: center;
    background-color: #fff;
    background-image: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 3rem auto 0 auto;
}
.promotion div.body h4{
    margin-bottom: 1rem;
}
.promotion div.body p{
    display: none;
}
.promotion div.body h4,
.promotion div.body p{
    color: #222;
}
.promotion div.video{
    line-height: 0;
}
.promotion div.video video{
    width: 100%;
    margin: 0 0 0 auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

    .tour h2.title,
    .step-wrap h2.title,
    .faq-wrap h2.title {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .tour {
        margin-bottom: 2rem;
    }

    .tour .card {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 0;
    }
    .step-wrap,
    .faq-wrap {
        margin-bottom: 2rem;
    }
    .step,
    .faq {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        width: 100%;
    }
    .step-item,
    .faq-item {
        width: 100%;
        min-height: auto;
        flex-wrap: wrap;
        margin: 0;
        padding: 0 1rem;
    }
    .step-item:first-child,
    .faq-item:first-child {
        margin-left: 0;
    }
    .step-item{
        min-height: 200px;
        background-size: 40%;
        background-position: 105% 30px;
        align-content: flex-start;
    }
    .step-item.step-1{
        background-size: 24%;
        background-position: 95% 40px;
    }
    .step-item p{
        background-color: transparent;
        margin-top: 20px;
        padding: 0 90px 0 0;
    }
    .step-item > h5{
        padding-left: 70px;
    }
    .step-item > h5:before{
        top: -1.5rem;
        left: -.5rem;
        width: 70px;
        height: 70px;
        background-repeat: no-repeat; 
    }
    .faq-item > h5{
        padding-left: 70px;
        min-height: 3rem;
        margin-top: 1rem;

    }
    .faq-item > h5:before{
        background-size: 70px;
    }
    .faq-item > p{
        background-size: 70px;
    }
}
</style>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Vue from 'vue'
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    name: 'Top',
    components: {
        VueSlickCarousel
    },
    data() {
        return {
            settings: {
                "fade": true,
                "edgeFriction": 0.35,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "autoplay": true,
                "adaptiveHeight": true
            }
        }
    },
    mounted() {
        this.parameters = {
            sortOrder: "popular",
            numInPage: 6,
            pageNo: 1,
            imageNum: 1
        }
        this.searchTourFromAPI()
    },
    computed: {
        ...mapGetters({
            getTourInfos: 'tour/getTourAndImages',
            userId: 'authentication/getLoginId',
            reservedTourList: 'tour/getTourList',
        })
    },
    methods: {
        ...mapActions(['tour/searchTour']),
        ...mapActions({
            searchUserTours: 'tour/userTours'
        }),
        // topPageToTourListPage() {
        //     this.$router.push({
        //         name: "TourList",
        //     });
        // },
        clickHowToUse() {
            this.$router.push({
                name: "HowToUse",
            });
        },
        async searchTourFromAPI() {
            Vue.set(this.parameters, "place", "");
            Vue.set(this.parameters, "freewords", "");
            Vue.set(this.parameters, "wanted", "");
            try {
                this.$parent.showLoading();
                await this.$store.dispatch('tour/searchTour', this.parameters)
                if (!(this.userId == undefined || this.userId == null)) {
                    await this.searchUserTours(this.userId)
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.$parent.hiddenLoading();
            }
        }
    }
}
</script>
