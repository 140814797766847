<template>
<div class="content text-center mb-3">
    <h2 class="font-weight-bold  mb-5">ページのロードにエラーを発生しました。</h2>
    <!-- <button class="btn btn-warning" v-on:click="goBack"><i class="las la-undo"></i>前ページに戻る</button> -->
</div>
</template>

<script>
export default {
    name: "NotFound",
    beforeRouteEnter(to, from, next) {
        if (from.name != 'NotFound') {
            next(vm => {
                vm.prevRoute = from.name
            })
        }
    },
    methods: {
        goBack() {
            alert(this.prevRoute)
            this.$router.push({
                name: this.prevRoute
            });
        }
    },
};
</script>
