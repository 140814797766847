<template>
<div class="wrap">
    <h3>{{detailsTitle}}</h3>
    <div class="tour-plan">
        <div class="apply">
            <dl class="picture">
                <dt><label>イメージ</label></dt>
                <template v-if="tourInfo.imageCount > 0">
                    <dd>
                        <img border="0" :src='apiUrl + "user/tourImage/" + tourInfo.tourId + "/1"' >
                    </dd>
                </template>
                <dd v-else></dd>
            </dl>
            <dl>
                <dt><label>見出し</label></dt>
                <dd>{{tourInfo.heading}}</dd>
            </dl>
            <dl v-if="false">
                <dt><label>募集期間</label></dt>
                <dd v-if="tourInfo.releaseStartDate == undefined"></dd>
                <dd v-else>{{tourInfo.releaseStartDate| date_format("YYYY年MM月DD日 (ddd)") }}～{{tourInfo.releaseEndDate | date_format("YYYY年MM月DD日 (ddd)") }}</dd>
            </dl>
            <dl>
                <dt><label>日時</label></dt>
                <dd v-if="tourInfo.tourDate!=undefined">{{tourInfo.tourDate | date_format("YYYY年MM月DD日 (ddd)") }} {{tourInfo.tourTimeFrom}}～{{tourInfo.tourTimeTo}} </dd>
                <dd v-else></dd>
            </dl>
            <dl>
                <dt><label>ツアープランナー</label></dt>
                <dd>{{tourInfo.plannerName}}</dd>
            </dl>
            <dl>
                <dt><label>ツアー内容</label></dt>
                <dd>{{tourInfo.content}}</dd>
            </dl>
            <dl>
                <dt><label>お土産</label></dt>
                <dd>{{tourInfo.souvenir}}</dd>
            </dl>
            <dl>
                <dt><label>行程</label></dt>
                <dd>{{tourInfo.path}}</dd>
            </dl>
            <dl>
                <dt><label>ツアーの流れ</label></dt>
                <dd>{{tourInfo.flow}}</dd>
            </dl>
            <dl>
                <dt><label>旅行代金</label></dt>
                <dd v-if="tourInfo.tourId == null"></dd>
                <dd v-else-if="tourInfo.price!=undefined">{{tourInfo.price | number_format }}<span>円</span></dd>
                <dd v-else></dd>
            </dl>
            <dl>
                <dt><label>振込先</label></dt>
                <dd>{{tourInfo.payee}}</dd>
            </dl>
        </div>
        <div class="apply timeline ">
            <dl>
                <dt><label>備考 </label></dt>
                <dd>{{tourInfo.remarks}}</dd>
            </dl>
            <dl>
                <dt><label>キャンセル規定</label></dt>
                <dd>{{tourInfo.cancelInfo}}</dd>
            </dl>
            <dl>
                <dt>
                    <label>注意事項
                        <div class="small text-danger">よくお読みください</div>
                    </label>
                </dt>
                <dd class="text-danger">{{tourInfo.notes}}</dd>
            </dl>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "tourDetailsCard",
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            tourDateAndTime: ""
        }
    },
    props: {
        tourInfo: {
            tourDate: {
                type: String,
            },
            tourTimeFrom: {
                type: String,
            },
            tourTimeTo: {
                type: String,
            },
            plannerName: {
                type: String,
            },
            content: {
                type: String,
            },
            flow: {
                type: String,
            },
            souvenir: {
                type: String,
            },
            path: {
                type: String,
            },
            price: {
                type: String,
            },
            payee: {
                type: String,
            },
            cancelInfo: {
                type: String,
            },
            remarks: {
                type: String,
            },
            notes: {
                type: String,
            },
        },
        tourImage: {
            type: Array
        },
        detailsTitle: String
    },
}
</script>

<style scoped>
.wrap {
    margin-top: 1.5rem;
}

.tour-plan {
    display: flex;
    width: 100%;
    margin-top: 1rem;
}

.apply {
    width: 50%;
    white-space: pre-wrap;
    border: 1px solid #808080;
}

.apply:first-child {
    width: 50%;
    border-right: 0 none;
}

.apply>dl {
    display: flex;
    margin: 0;
}

.apply>dl>dt {
    min-width: 11rem;
    min-height: 3.2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: normal;
    font-size: 1rem;
    padding: .5rem 1rem .5rem 0;
    color: #0166cc;
    background-color: #e2f0ff;
    border-bottom: 1px solid #acc8e5;
}

.apply>dl>dt label {
    margin: 0;
}

.apply>dl>dd {
    padding: .5rem 0 .5rem 1rem;
    width: 100%;
    min-height: 3.2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    margin: 0;
    word-break: break-all;
    word-wrap: break-word;
}

.apply>dl:last-child>dt,
.apply>dl:last-child>dd {
    border-bottom: 0;
}

.apply>dl>dd.apply-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
}

.apply-child dl {
    width: 100%;
    display: flex;
    align-items: stretch;
    margin: 0;
}

.apply-child dl:first-child dt,
.apply-child dl:first-child dd {
    border-top: 0;
}

.apply-child dl dt {
    min-width: 8rem;
    text-align: right;
    padding: .5rem .5rem .5rem 0;
    color: #0166cc;
    background-color: #f2f8ff;
    border-top: 1px solid #cbdef4;
}

.apply-child dl dd {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    padding: .5rem 0 .5rem .75rem;
}

.apply>dl.picture>dd {
    padding: .5rem;
}

.apply>dl.picture>dd img {
    width: 100px;
}

.apply.timeline dl {
    flex-direction: column;
}

.apply.timeline dl dt {
    min-width: auto;
    min-height: auto;
    justify-content: flex-start;
    font-size: 1rem;
    padding: .5rem 0 0 .5rem;
    color: #666;
    background-color: transparent;
    border-bottom: 0 none;
}

.apply.timeline dl dt label {
    display: block;
    color: #0166cc;
    background-color: #e2f0ff;
    padding: .3rem .5rem;
    border-radius: 3px;
}

@media screen and (max-width: 767px) {
    .content {
        margin-top: 5rem;
    }

    .tour-plan {
        margin-top: 0;
        flex-direction: column;
    }

    .apply {
        width: 100%;
        border: 0 none;
    }

    .apply:first-child {
        margin-top: 2rem;
        width: 100%;
    }

    .apply>dl {
        flex-direction: column;
        margin: 0 .25rem .5rem .25rem;
    }

    .apply>dl>dt {
        min-width: auto;
        min-height: auto;
        display: block;
        font-size: .85rem;
        padding: 0 0 .25rem 0;
        background-color: transparent;
        border-bottom: 1px solid #acc8e5;
    }

    .apply>dl>dd {
        min-height: auto;
        padding: .5rem;
        width: 100%;
        border-bottom: 0 none;
    }

    .apply.timeline dl {
        flex-direction: column;
    }

    .apply.timeline dl dt {
        font-size: .85rem;
        padding: 0;
    }

    .apply.timeline dl dt label {
        display: block;
        color: #0166cc;
        background-color: transparent;
        padding: 0 0 .25rem 0;
        border-bottom: 1px solid #acc8e5;
        border-radius: 0;
    }

}
</style>
