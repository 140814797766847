export default {
    getToken(state) {
        return state.token;
    },
    getLoginId(state) {
        return state.loginId;
    },
    getLoginName(state) {
        return state.loginName;
    },
    isAuthenticated(state) {
        return !!state.token;
    },
};