<template>
<div class="content">
    <h2 class="title">くわしいご利用のステップ<button class="btn btn-light btn-back" @click="clickTopPage"><i class="las la-angle-left"></i> トップへ戻る</button></h2>
    <div class="list">
        <div class="entry">
            <h4>お申し込み</h4>
            <p>ツアー一覧からお好きなツアープランを選んで、申し込みます。</p>
        </div>
        <div class="email">
            <h4>予約完了メールの受信</h4>
            <p>予約完了のメールが届きます。<br>1日程度かかることがありますので、しばらくお待ちください。</p>
        </div>
        <div class="payment">
            <h4>旅行代金のお支払い</h4>
            <p>予約完了メールに記載されているお支払い方法にしたがって、料金をお支払いください。現在、銀行振込のみ受け付けております。</p>
        </div>
        <div class="email">
            <h4>入金確認メールの受信</h4>
            <p>入金が確認できたら、ぷらっとワ〜プより入金確認のメールが届きます。</p>
        </div>
        <div class="email">
            <h4>発送完了メールの受信</h4>
            <p>各ツアー担当者より、商品が発送されます。発送が完了したら、発送完了メールが届きます。</p>
        </div>
        <div class="package">
            <h4>商品の受け取り</h4>
            <p>宅配された商品をお受取りください。</p>
        </div>
        <div class="tour">
            <h4>オンラインツアーへの参加</h4>
            <p>発送完了メールに記載されているツアーへの参加方法、視聴方法にしたがって準備をし、当日のツアーへのご参加をお願いいたします。<br>当サービスではZoomアプリを使用して視聴を行います。お持ちのパソコンやスマートフォンなどでZoomアプリのインストールなどのご準備をお願いします。
            </p>
        </div>
        <div class="complete">
            <h4>ツアー完了</h4>
            <p>ツアーへの参加ありがとうございました。お楽しみいただけたことを願っております。</p>
        </div>
    </div>
</div>
</template>

<style scoped>
.list{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}
.list > div{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 700px;
    min-height: 13rem;
    border: 2px solid #0260DF;
    border-radius: 5px;
    margin: 50px auto 0 auto;
    background-repeat: no-repeat;
    background-position: 102% 160%;
    background-size: 145px;
}
.list > div:before{
    position: absolute;
    left: calc(50% - 25px);
    bottom: -42px;
    content:'';
    background: url("../../static/images/arrow-bottom.svg") no-repeat;
    width: 50px;
    height: 25px;
    background-size: 100%;
}
.list > div:first-child{
    margin-top: 20px;
}
.list > div:last-child:before{
    display: none;
}
.list > div.entry{
    background-image: url("../../static/images/like.svg");
}
.list > div.email{
    background-image: url("../../static/images/email.svg");
}
.list > div.package{
    background-image: url("../../static/images/box.svg");
}
.list > div.tour{
    background-image: url("../../static/images/chat.svg");
}
.list > div.payment{
    background-image: url("../../static/images/coins.svg");
}
.list > div.complete{
    background-image: url("../../static/images/stars.svg");
}
.list > div > h4{
    color: #fff;
    background-color: #0260DF;
    padding: .7rem 1rem;
}
.list > div > p{
    padding: 0 140px 0 .8rem;
    font-size: 1rem;
}
@media screen and (max-width: 767px) {

    .list > div{
        width: 100%;
        padding-bottom: 70px;
        border-left: 0 none;
        border-right: 0 none;
        border-bottom: 0 none;
        border-radius: 0;
        background-position: 95% 90%;
        background-size: 70px;
        margin-top: 2rem;
    }
    .list > div:before{
        bottom: -20px;
    }
    .list > div > p{
        min-height: auto;
        padding: 0 .8rem;
    }
}
</style>

<script>
export default {
    methods: {
        clickTopPage() {
            this.$router.go(-1);
        },
    }
}
</script>
