import Vue from "vue";
import moment from 'moment'

Vue.filter("number_format", function(value) {
    if (value && String(value).length > 0) {
        return value.toLocaleString();
    }
    else {
        return value;
    }
});

Vue.filter("date_format", function(value, format) {
    if(!format || format == '') {
        format = 'YYYY年MM月DD日 (ddd) HH:mm';
    }

    if (value && value.length) {
        var date = value.replace(/-/g, '/').replace(/\.[0-9]+/g, '');
        return moment(new Date(date)).locale('ja').format(format);
    } else {
        return value;
    }
});

Vue.filter("tour_date_format", function(value, option) {
    if (value && value.length == 8) {
        if (option == 'y') {
            return parseInt(value.substring(0, 4));
        } else if (option == 'm') {
            return parseInt(value.substring(4, 6));
        } else if (option == 'd') {
            return parseInt(value.substring(6, 8));
        } else {
            return value;
        }
    } else {
        return '';
    }
});
Vue.filter("tour_time_format", function(value) {
    if (value && value.length == 4) {
        return value.substring(0, 2) + ":" + value.substring(2, 4);
    } else {
        return '';
    }
});
