export default {
    setTourId(state, payload){
      state.tourId=payload;
    },
    setNumberOfFellow(state, payload){
      state.numberOfFellow=payload;
    },
    setPaymentMethod(state, payload){
      state.paymentMethod=payload;
    },
    setPaymentMethodId(state, payload){
      state.paymentMethodId=payload;
    },
    setReservedUserList(state, payload){
      state.reservedUserList=payload;
    },
    setIsReservationOk(state, payload){
      state.isReservationOk=payload;
    },
    setPreviousPage(state, payload){
      state.previousPage=payload;
    }
    
}