export default {
    getTourAndImages(state) {
      return state.tourAndImages;
    },
    getTotalPage(state) {
      return state.totalPage;
    },
    getTourList(state) {
      return state.tourList;
    },
};