export default {
  getProviderToken(state) {
      return state.providerToken;
  },
  getProviderLoginId(state) {
      return state.providerLoginId;
  },
  getProviderLoginName(state) {
      return state.providerLoginName;
  },
  isAuthenticatedProvider(state) {
      return !!state.providerToken;
  },
};