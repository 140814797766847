export default {
  getSexs(state) {
    return state.sexs;
  },
  getPaymentOptions(state) {
    return state.paymentOptions;
  },
  getAreas(state) {
    return state.areas;
  },
  getStatus(state) {
    return state.status;
  },
};