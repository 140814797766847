export default {
    getTourId(state) {
      return state.tourId;
    },
    getNumberOfFellow(state) {
      return state.numberOfFellow;
    },
    getPaymentMethod(state) {
      return state.paymentMethod;
    },
    getPaymentMethodId(state) {
      return state.paymentMethodId;
    },
    getReservedUserList(state) {
      return state.reservedUserList;
    },
    getIsReservationOk(state) {
      return state.isReservationOk;
    },
    getPreviousPage(state) {
      return state.previousPage;
    },
};