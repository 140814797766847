<template>
<div id="header">
    <div class="inner">
        <div class="logo">
            <a href="/Provider/TourList" rel="providerTourList">
                <h1><img src="../static/images/logo_header.svg" alt="ぷらっとワ〜プ"></h1>
            </a>
        </div>
        <div v-if="$route.name!='LoginProvider'" class="user-info">
            <div v-if="isProviderLoggedIn" class="pre">
                <label>ようこそ</label>
                <label class="user-name" v-if="providerLoginName!=null">{{providerLoginName}}</label>
                <label>さん</label>
            </div>
            <div v-else class="pre">
                <label>ログインIDをお持ちの方</label>
            </div>
            <div id="header-menu" v-if="!isProviderLoggedIn">
                <button type="button" class="btn btn-info" @click="loginProvider">
                    <i class="las la-user-lock"></i><span>提供者ログイン</span>
                </button>
            </div>
            <div id="header-menu" v-else>
                <button v-if="($route.name!='ProviderPasswordChange')" type="button" class="btn btn-info" @click="changeProviderPassword">
                    <i class="las la-user-circle"></i><span>パスワード変更</span>
                </button>
                <button type="button" class="btn btn-light" @click="logout">
                    <i class="las la-door-open"></i><span>ログアウト</span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
#header {
    background-color: #fff;
    width: 100%;
    min-height: 80px;
}

#header .inner {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-items: center;
}

#header h1 {
    width: 283px;
    margin: 6px 0;
}

#header .user-info {
    display: flex;
    margin-left: auto;
    align-items: center;
}

.user-name {
    margin: 0 .5rem;
    font-size: 1rem;
    color: #222;
}

.pre {
    font-size: .9rem;
    margin-right: .7rem;
    margin-top: .5rem;
    color: #444;
}

#header-menu {
    display: flex;
}

#header-menu>.btn {
    display: flex;
    margin-right: .7rem;
}

#header-menu>.btn>i {
    font-size: 24px;
    margin-right: 5px;
}

#header-menu>.btn:last-child {
    margin-right: 0;
}

@media screen and (max-width: 767px) {
    #header .inner {
        flex-direction: column;
    }

    #header h1 {
        min-width: 100%;
        margin: 6px 0;
    }

    #header .user-info {
        flex-direction: column;
        margin: 0 0 .7rem 0;
    }
}
</style>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'
export default {
    computed: {
        ...mapGetters({
            providerLoginId: 'providerAuthentication/getProviderLoginId',
            providerLoginName: 'providerAuthentication/getProviderLoginName',
            isProviderLoggedIn: 'providerAuthentication/isAuthenticatedProvider'
        }),
    },
    methods: {
        ...mapActions(['providerAuthentication/logoutProvider']),
        loginProvider() {
            this.$router.push({
                name: "LoginProvider",
                params: {
                    tourId: null,
                    callback: "ProviderTourList"
                },
            });
        },
        changeProviderPassword() {
            this.$router.push({
                name: "ProviderPasswordChange",
                params: {
                    providerId: this.providerLoginId,
                },
            });
        },
        logout() {
            var result = window.confirm('ログアウトします。\r\nよろしいですか？');
            if (result) {
                this.$store.dispatch('providerAuthentication/logoutProvider');
                this.$router.push({
                    name: "LoginProvider",
                });
            }
        },
    }
}
</script>
