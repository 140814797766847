<template>
<div class="card image-preview" v-if="fileInfo=='empty'||isHideThisDiv==true">
    <img class="bg-placeholder-img card-img-top image-preview-img" :src="dataUrl">
    <label class="px-2 text-right" v-if="file.name">{{ file.name }}</label>
    <label class="px-2 text-right" v-else></label>
    <div class="card-body">
        <div class="card-text">
            <div class="custom-file mb-sm-2">
                <input type="file" id='tour_pic1' accept="image/*" @change="selectFile" class="custom-file-input image-preview-input" name="tour_pic1">
                <label class="custom-file-label" for="tour_pic1">{{ file.name }}</label>
            </div>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" :id="'checkBox${deleteImageCheckBox}'" v-model="items[deleteImageCheckBox]" @change="updateValue(deleteImageCheckBox)" :disabled="routeName=='ProviderTourDelete'||routeName=='ProviderTourChangeComplete'||dataUrl==undefined" />
                <label class="ml-2">削除する</label>
            </div>
        </div>
    </div>
</div>
<div v-else class="card image-preview">
    <img class="bg-placeholder-img card-img-top image-preview-img" v-if="file.imageData!='undefined'" :src="'data:'+file.imageType+';base64,'+file.imageData" accept="image/*">
    <label class="px-2 text-right" v-if="file.imageName">{{ file.imageName }}</label>
    <label class="px-2 text-right" v-else></label>
    <div class="card-body" v-if="routeName!='ProviderTourChangeComplete'">
        <div class="card-text">
            <div class="custom-file mb-sm-2">
                <input type="file" id='tour_pic1' accept="image/*" @change="updateFile" class="custom-file-input image-preview-input" name="tour_pic1" :disabled="routeName=='ProviderTourDelete'||routeName=='ProviderTourChangeComplete'">
                <label class="custom-file-label" for="tour_pic1">{{ file.imageName }}</label>
            </div>
            <div>
                <input type="checkbox" :id="'checkBox${deleteImageCheckBox}'" v-model="items[deleteImageCheckBox]" @change="updateValue(deleteImageCheckBox)" :disabled="routeName=='ProviderTourDelete'||routeName=='ProviderTourChangeComplete'||file.imageData==undefined" />
                <label class="ml-2">削除する</label>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "imageInputCard",
    data() {
        return {
            dataUrl: undefined,
            file: this.fileInfo,
            values: [],
            items: [
                false,
                false,
                false
            ],
            deleteImageCheckBox: this.index,
            isHideThisDiv: false
        };
    },
    props: {
        fileInfo: {},
        index: {
            type: Number,
            default: 0
        },
        routeName: {
            type: String,
            default: "ProviderTourChangeComplete"
        }
    },
    methods: {
        // show the preview, when select the image.
        selectFile(event) {
            if (event.target.value.length) {
                var bufferFile = event.target.files[0]
                //Check if the selected file is valid file(only image file)
                if (bufferFile.type == "image/jpeg" || bufferFile.type == "image/jpg" || bufferFile.type == "image/png" || bufferFile.type == "image/gif") {
                    //Check if the selected image file bigger than 10MB
                    if (((bufferFile.size) / 1024 / 1024) <= 10) {
                        this.file = event.target.files[0];
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            this.dataUrl = e.target.result;
                            this.getImageData()
                        }
                        reader.readAsDataURL(this.file);
                    } else {
                        // ファイルサイズが10MB未満の画像を選択してください。
                        alert(this.messageError('1105', 'ファイルサイズが10MB未満の画像'))
                    }
                } else {
                    // 画像ファイルのみを選択してください。
                    alert(this.messageError('1105', '画像ファイルのみを'))
                }
            }
        },
        // this is method for parent view gets this component's values.
        getImageData() {
            var result = this.file;
            result.data = this.dataUrl;
            result.data = result.data.substring(result.data.indexOf(";base64,") + (";base64,").length)
            var finalResult = {
                //Remove upto ";base64," from the base64 value of the selected image. Save only the string after ";base64,"
                imageData: result.data,
                imageName: result.name,
                imageType: result.type,
                imageSize: result.size
            }
            this.$emit('changeImageInfo', finalResult)
            this.isHideThisDiv = true
        },
        //this method is called if the image is already existing and is image update.
        async updateFile(event) {
            var bufferFile = event.target.files[0]
            //Check if the selected file is valid file(only image file)
            if (bufferFile.type == "image/jpeg" || bufferFile.type == "image/jpg" || bufferFile.type == "image/png" || bufferFile.type == "image/gif" || bufferFile.type == "image/webp") {
                //Check if the selected image file bigger than 10MB
                if (((bufferFile.size) / 1024 / 1024) <= 10) {
                    this.selectedImg = event.target.files[0]
                    this.createBase64Image(this.selectedImg)
                } else {
                    // ファイルサイズが10MB未満の画像を選択してください。
                    alert(this.messageError('1105', 'ファイルサイズが10MB未満の画像'))
                }
            } else {
                // 画像ファイルのみを選択してください。
                alert(this.messageError('1105', '画像ファイルのみ'))
            }
        },
        createBase64Image(fileObject) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result;
                //Remove upto ";base64," from the base64 value of the selected image. Save only the string after ";base64,"
                this.image = this.image.substring(this.image.indexOf(";base64,") + (";base64,").length)
                this.file = {
                    imageData: this.image,
                    imageName: fileObject.name,
                    imageType: fileObject.type,
                    imageSize: fileObject.size
                }
                this.$emit('changeImageInfo', this.file)
            };
            reader.readAsDataURL(fileObject)
        },
        updateValue: function (val) {
            this.$emit("deleteImageInput", this.items[val]);
        }
    }
}
</script>

<style scoped>
.card img {
    background-color: lightgray;
    height: 200px;
    width: auto;
    object-fit: contain;
}

.image-preview>label {
    min-height: 1rem;
}
</style>
