import Axios from "axios";
export default {
  async postReservation(context, payload) {
    var getAccessToken =
      "Bearer " + context.rootGetters["authentication/getToken"];
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "user/reservations";
    var reservationParams = {
      tourId: payload.tourId,
      numberOfFellow: payload.numberOfFellow,
      paymentMethodNo: payload.paymentMethod,
      expectedAmount: payload.expectedAmount,
    };
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };

    await new Promise((resolve, reject) => {
      Axios.post(apiUrl, reservationParams, getHeader)
        .then((response) => {
          if (response.data.processCheck == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  async reservationExistCheck(context, payload) {
    var getAccessToken =
      "Bearer " + context.rootGetters["authentication/getToken"];
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "user/reservations/" + "?tourIds=" + payload.tourId;
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        if (response.data.reservation.length < 1) {
          context.commit("setIsReservationOk", true);
        } else {
          context.commit("setIsReservationOk", false);
          context.commit(
            "setNumberOfFellow",
            response.data.reservation[0].numberOfFellow
          );
          context.commit(
            "setPaymentMethodId",
            response.data.reservation[0].paymentMethodNo
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getReservationLists(context, payload) {
    var getAccessToken =
      "Bearer " +
      context.rootGetters["providerAuthentication/getProviderToken"];
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "provider/reservations/ReservedUserList/" + payload.tourId;
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };
    await Axios.get(apiUrl, getHeader)
      .then((response) => {
        context.commit("setReservedUserList", response.data.users);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  SaveTourIdInputs(context) {
    const tourId = sessionStorage.getItem("tourId");
    if(tourId){
      context.commit('setTourId', tourId)
    }
    sessionStorage.removeItem("tourId");
  },
  SaveReservationInputs(context) {
    const numberOfFellow = sessionStorage.getItem("numberOfFellow");
    const paymentMethod = sessionStorage.getItem("paymentMethod");
    const paymentMethodId = sessionStorage.getItem("paymentMethodId");
    // alert(numberOfFellow)
    if(numberOfFellow&&paymentMethodId){
      context.commit('setNumberOfFellow', numberOfFellow)
      context.commit('setPaymentMethod', paymentMethod)
      context.commit('setPaymentMethodId', paymentMethodId)
    }
    sessionStorage.removeItem("numberOfFellow");
    sessionStorage.removeItem("paymentMethod");
    sessionStorage.removeItem("paymentMethodId");
  },
  SaveResultPreviousPage(context) {
    const previousPage = sessionStorage.getItem("reservationResult_PreviousPage");
    if(previousPage){
      context.commit('setPreviousPage', previousPage)
    }
    sessionStorage.removeItem("reservationResult_PreviousPage");
  },

  async cancelReservation(context, payload) {
    var getAccessToken =
      "Bearer " + context.rootGetters["authentication/getToken"];
    var apiUrl = process.env.VUE_APP_API_URL;
    apiUrl += "user/reservations/" + payload.tourId;
    var getHeader = {
      headers: {
        Authorization: getAccessToken,
        "Content-Type": "application/json",
      },
    };

    await new Promise((resolve, reject) => {
      Axios.delete(apiUrl, getHeader)
        .then((response) => {
          if (response.data.processCheck == true) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};
